import { Box, Typography } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/@common/Icon';

interface Props {
  titleId: string;
  bodyId: string;
}

const Error: React.VFC<Props> = ({ titleId, bodyId }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Icon size={56} mb={4} Icon={ReportProblemIcon} />
      <Typography variant="h4" component="h1">
        <FormattedMessage id={titleId} />
      </Typography>
      <Box mt={2}>
        <Typography variant="body1" color="textSecondary">
          <FormattedMessage id={bodyId} />
        </Typography>
      </Box>
    </Box>
  );
};

export default Error;
