import React from 'react';

import { Box, Button, Container } from '@material-ui/core';
import { NextPage } from 'next';
import { FormattedMessage } from 'react-intl';
import { Routes } from 'routes';

import Background from 'components/@common/Background';
import Error from 'components/@common/Error';
import Link from 'components/@navigation/Link';

const NotFound: NextPage = () => {
  return (
    <Box height="100%" alignItems="center" display="flex" textAlign="center">
      <Background />
      <Container maxWidth="sm">
        <Error titleId="page.404.title" bodyId="page.404.body" />
        <Box mt={4} mb={2}>
          <Button component={Link} href={Routes.home} variant="contained" color="primary" fullWidth>
            <FormattedMessage id="page.404.home.button" />
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
