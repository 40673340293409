import React from 'react';

import { Box, BoxProps, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface Props extends BoxProps {
  color?: string;
  size: number | string;
  Icon: OverridableComponent<SvgIconTypeMap>;
}
const Icon = ({ color = 'grey.300', children, size, Icon, ...rest }: Props) => {
  return (
    <Box color={color} fontSize={size} display="flex" justifyContent="center" {...rest}>
      <Icon fontSize="inherit" />
    </Box>
  );
};

export default Icon;
